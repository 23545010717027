<template>
  <div class="w-full">
    <div class="h-64 overflow-hidden rounded-md shadow-lg">
      <apex-chart width="100%" height="100%" type="bar" :options="options" :series="series" />
    </div>
  </div>
</template>

<script>
export default {
  name: "EventBarChart",

  props: {
    series: {
      type: Array,
    },
    yearly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      options: {
        legend: {
          show: false,
        },
        colors: ["#467486"],
        xaxis: {
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          categories: [
            this.yearly ? this.$t("pages.statistics.last_year") : this.$t("pages.statistics.last_month"),
            this.yearly ? this.$t("pages.statistics.current_year") : this.$t("pages.statistics.current_month"),
          ],
          labels: {
            style: {
              fontFamily:
                'Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            },
          },
        },
        yaxis: {
          min: 0,
          axisTicks: {
            show: false,
          },
          labels: {
            formatter: (value) => value.toFixed(0),
            style: {
              fontFamily:
                'Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        chart: {
          background: "#fff",
          height: "100%",
          toolbar: {
            show: false,
          },
        },
      },
    };
  },
};
</script>

<style scoped></style>
