<template>
  <div class="w-full">
    <div class="event-chart overflow-hidden rounded-md shadow-lg">
      <apex-chart
        width="100%"
        height="100%"
        type="bar"
        :options="options"
        :series="series"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    series: {
      type: Array
    },
    categories: {
      type: Array
    }
  },

  data: function() {
    return {
      options: {
        colors: [
          function({ value }) {
            if (value < 2) {
              return "#7AA5FF";
            } else if (value < 3) {
              return "#14CDE3";
            } else if (value < 4) {
              return "#2BFDA1";
            } else if (value < 5) {
              return "#ECE96B";
            } else {
              return "#FFC330";
            }
          }
        ],
        xaxis: {
          categories: this.categories,
          axisTicks: {
            show: false
          },
          axisBorder: {
            show: false
          },
          labels: {
            style: {
              fontFamily:
                'Montserrat, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
            }
          }
        },
        yaxis: {
          min: 0,
          max: 5,
          tickAmount: 5,
          axisTicks: {
            show: false
          },
          labels: {
            align: "center",
            formatter: value => {
              if (value >= 0) {
                return "";
              }

              return value.toFixed(0);
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          enabled: false
        },
        chart: {
          background: "#fff",
          height: "100%",
          toolbar: {
            show: false
          }
        },
        grid: {
          padding: {
            top: 0,
            right: 20,
            bottom: 0,
            left: 28
          }
        },
        annotations: {
          position: "front",

          images: [
            {
              path: require("@/assets/moods/images/mood_angry.png"),
              x: 10,
              y: 168,
              width: 25,
              height: 25,
              appendTo: ".apexcharts-yaxis"
            },
            {
              path: require("@/assets/moods/images/mood_sad.png"),
              x: 10,
              y: 130,
              width: 25,
              height: 25,
              appendTo: ".apexcharts-yaxis"
            },
            {
              path: require("@/assets/moods/images/mood_neutral.png"),
              x: 10,
              y: 92,
              width: 25,
              height: 25,
              appendTo: ".apexcharts-yaxis"
            },
            {
              path: require("@/assets/moods/images/mood_good.png"),
              x: 10,
              y: 55,
              width: 25,
              height: 25,
              appendTo: ".apexcharts-yaxis"
            },
            {
              path: require("@/assets/moods/images/mood_satisfied.png"),
              x: 10,
              y: 16,
              width: 25,
              height: 25,
              appendTo: ".apexcharts-yaxis"
            }
          ]
        }
      }
    };
  }
};
</script>

<style scoped>
.event-chart {
  min-height: 16rem;
}
</style>
