<template>
  <div
    class="w-full p-4 h-28 rounded-lg shadow-md bg-white relative overflow-hidden"
  >
    <div class="flex flex-col justify-center relative z-30 h-full">
      <h4 class="font-bold text-2xl text-brand-dark mb-2">{{ data }}</h4>
      <p class="text-brand-dark text-sm">{{ title }}</p>
    </div>
    <mood-icon
      v-if="moodIcons.includes(icon)"
      :value="icon"
      class="absolute w-48 -right-20 -top-8 z-20 opacity-50"
    />

    <component
      v-else
      :is="icon"
      class="absolute text-brand-primary w-36 -right-12 -top-4 z-20 opacity-50 fill-current"
    />
  </div>
</template>

<script>
import MoodIcon from "../MoodIcon";
import ClockIcon from "assets/icons/clock.svg";
import EventsIcon from "assets/icons/event-map-pin.svg";

export default {
  components: {
    MoodIcon,
    ClockIcon,
    EventsIcon,
  },

  props: {
    data: {
      type: [Number, String],
    },

    title: {
      type: String,
    },

    icon: {
      type: String,
    },
  },

  data() {
    return {
      moodIcons: ["angry", "sad", "neutral", "good", "satisfied"],
    };
  },
};
</script>

<style></style>
