<template>
  <wrapper>
    <div class="flex-grow-1 mx-auto flex w-full flex-col">
      <h1 class="mb-4 text-2xl font-bold">
        {{ $t("pages.statistics.title") }}
      </h1>

      <div
        class="flex flex-col-reverse items-center justify-between space-y-2 rounded-lg bg-gray-50 p-2 2xs:flex-row 2xs:space-y-0">
        <div
          class="flex w-full flex-1 flex-col flex-wrap items-center space-y-0 text-center text-xl font-bold text-brand-dark 2xs:w-auto 2xs:flex-auto 2xs:flex-row">
          <div class="w-full flex-1 2xs:w-auto 2xs:flex-auto">
            <t-select v-if="period.month !== null" ref="monthRef" variant="stats" :options="period_month_range()"
              :disabled="period_month_range().length <= 1" v-model="period.month" class="flex-initial flex-shrink-0"
              w-full />
          </div>

          <div class="w-full flex-1 2xs:w-auto 2xs:flex-auto">
            <t-select variant="stats" :options="period_year_range" v-model="period.year"
              :disabled="period_year_range.length <= 1" class="flex-initial flex-shrink-0" />
          </div>
        </div>
        <div
          class="mb-4 flex flex-col items-center justify-center space-y-1 pb-2 2xs:mb-0 2xs:flex-row 2xs:pb-0 sm:items-baseline sm:justify-end sm:space-y-0">
          <t-button v-on:click="togglePeriodScope" variant="small" type="button">{{
          period.month == null
          ? $t("dates.datepick.yearly")
          : $t("dates.datepick.monthly")
          }}</t-button>
        </div>
      </div>
    </div>

    <loading v-if="loading" />

    <div class="grid-cols-2 gap-8 md:grid" v-else>
      <div class="my-4 space-y-2">
        <div class="space-y-2">
          <h3 class="text-xl font-bold">
            {{ $t("pages.statistics.events_mood_bar_title") }}
          </h3>

          <average-mood-bar-chart class="mt-2" :series="averageMoodSeries" :categories="[
            $t('pages.statistics.mood_before_event'),
            $t('pages.statistics.mood_after_event'),
            $t('pages.statistics.mood_after_two_days_event'),
          ]" />
        </div>

        <statistics-card :title="$t('pages.statistics.filled_in_moods')" :icon="getIconAndMood(avgMood)[0]"
          :data="totalMoods" />

        <statistics-card :title="$t('pages.statistics.participated_events')" icon="events-icon"
          :data="totalParticipatedEvents" />
      </div>

      <div class="my-4 space-y-3">
        <h3 class="text-xl font-bold">
          {{ $t("pages.statistics.evolution_events") }}
        </h3>

        <div class="space-y-2">
          <h4 class="text-lg font-bold">
            {{ $t("pages.statistics.amount_events") }}
          </h4>

          <event-bar-chart :series="eventsEvolutionSeries" :yearly="period.month === null" />
        </div>

        <div class="space-y-2">
          <h4 class="text-lg font-bold">
            {{ $t("pages.statistics.average_hours_at_event") }}
          </h4>

          <event-bar-chart :series="averageHoursAtEventSeries" :yearly="period.month === null" />
        </div>

        <div class="space-y-2">
          <h4 class="text-lg font-bold">
            {{ $t("pages.statistics.daily_mood_chart_title") }}
          </h4>

          <mood-bar-chart :series="moodSeries" :categories="dayLabels" />
        </div>
      </div>
    </div>
  </wrapper>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import StatisticRepository from "repositories/StatisticRepository";
import MoodBarChart from "components/Statistics/MoodBarChart";
import AverageMoodBarChart from "components/Statistics/AverageMoodBarChart";
import StatisticsCard from "components/Statistics/Card";
import EventBarChart from "components/Statistics/EventBarChart";

export default {
  name: "StatsIndex",

  components: {
    EventBarChart,
    AverageMoodBarChart,
    MoodBarChart,
    StatisticsCard,
  },

  data() {
    return {
      loading: false,
      response: null,

      dateFilter: undefined,
      period: { month: new Date().getMonth(), year: new Date().getFullYear() },
      avgMood: 0,

      months: [
        "january",
        "february",
        "march",
        "april",
        "may",
        "june",
        "july",
        "august",
        "september",
        "october",
        "november",
        "december",
      ],
    };
  },

  async mounted() {
    await this.getStatistics();
    this.getAverageMood();
  },

  methods: {
    togglePeriodScope() {
      if (this.period.month !== null) {
        this.updatePeriod({
          month: null,
          year: this.period.year || new Date().getMonth(),
        });
      } else {
        this.updatePeriod({
          month:
            this.period.year == new Date().getFullYear()
              ? new Date().getMonth()
              : this.period.year < new Date().getFullYear()
                ? 11
                : 0,
          year: this.period.year || new Date().getFullYear(),
        });
      }
    },
    updatePeriod(period) {
      this.period = period;
    },
    setResponse(data) {
      this.response = data;
    },
    resetResponse() {
      this.response = null;
    },

    async getStatistics() {
      this.loading = true;
      this.resetResponse();
      try {
        const { data: statisticsResponse } = await StatisticRepository.get(
          this.period
        );
        this.setResponse(statisticsResponse.data);
      } catch (e) {
        this.resetResponse();
      }
      this.loading = false;
    },

    getIconAndMood(moodTotal) {
      if (moodTotal <= 1) {
        return ["angry", this.$t("moods.angry")];
      } else if (moodTotal > 1 && moodTotal <= 2) {
        return ["sad", this.$t("moods.sad")];
      } else if (moodTotal > 2 && moodTotal <= 3) {
        return ["neutral", this.$t("moods.neutral")];
      } else if (moodTotal > 3 && moodTotal <= 4) {
        return ["good", this.$t("moods.good")];
      } else {
        return ["satisfied", this.$t("moods.satisfied")];
      }
    },

    period_month_range() {
      return Array(12)
        .fill()
        .map((_, idx) => {
          let isItDisabled = this.isMonthDisabled(idx);
          if (isItDisabled) return;
          return {
            label: this.$t("dates.months." + this.months[idx]),
            value: idx,
            disabled: isItDisabled || false,
          };
        })
        .filter(Boolean);
    },
    isMonthDisabled(value) {
      if (
        this.period.year == this.datepicker_year_range_from &&
        value < parseInt(this.datepicker_month_from)
      ) {
        return true;
      }
      if (
        this.period.year == this.datepicker_year_range_until &&
        value > parseInt(this.datepicker_month_until)
      ) {
        return true;
      }

      return false;
    },

    convertMsToHours(ms) {
      if (ms === 0) return 0;
      return ms / 1000 / 3600;
    },

    getAverageMood() {
      this.avgMood =
        this.moodSeries[0].data
          .filter((d) => d !== 0)
          .reduce((a, b) => a + b, 0) /
        this.moodSeries[0].data.filter((d) => d !== 0).length;
    },
  },

  computed: {
    ...mapGetters(["getMemberSince", "isCheckedIn"]),
    datepicker_year_range_from() {
      return this.getMemberSince instanceof Date
        ? this.getMemberSince.getFullYear()
        : 2021;
    },
    datepicker_month_from() {
      return this.getMemberSince instanceof Date
        ? this.getMemberSince.getMonth()
        : new Date().getMonth();
    },
    datepicker_month_until() {
      return new Date().getMonth();
    },
    datepicker_year_range_until() {
      return new Date().getFullYear();
    },
    datepicker_year_range() {
      return {
        from: this.datepicker_year_range_from,
        to: this.datepicker_year_range_until,
      };
    },
    period_year_range() {
      return Array(
        this.datepicker_year_range_until - this.datepicker_year_range_from + 1
      )
        .fill()
        .map((_, idx) => this.datepicker_year_range_from + idx);
    },
    dayLabels() {
      return [
        this.$t("dates.weekday_letter.monday"),
        this.$t("dates.weekday_letter.tuesday"),
        this.$t("dates.weekday_letter.wednesday"),
        this.$t("dates.weekday_letter.thursday"),
        this.$t("dates.weekday_letter.friday"),
        this.$t("dates.weekday_letter.saturday"),
        this.$t("dates.weekday_letter.sunday"),
      ];
    },
    totalMoods() {
      return this.response?.total_moods || 0;
    },
    totalDailyMoods() {
      return this.response?.total_daily_moods || 0;
    },
    totalParticipatedEvents() {
      return this.response?.total_current_month_events || 0;
    },
    averageHoursAtEvent() {
      return this.response?.average_ms_at_event
        ? moment(this.response.average_ms_at_event).hours()
        : 0;
    },

    moodSeries() {
      if (!this.response || !this.response.average_daily_moods) return [];
      return [{ data: this.response.average_daily_moods }]; // avgMoodWeekdayData.moods;
    },
    averageMoodSeries() {
      if (!this.response) return [];
      return [
        {
          data: [
            this.response?.average_before_event || 0,
            this.response?.average_after_event || 0,
            this.response?.average_mood_after_two_days || 0,
          ],
        },
      ];
    },
    eventsEvolutionSeries() {
      if (!this.response) return [];
      return [
        {
          data: [
            this.response?.total_last_month_events || 0,
            this.response?.total_current_month_events || 0,
          ],
        },
      ];
    },
    averageHoursAtEventSeries() {
      if (!this.response) return [];

      return [
        {
          data: [
            this.response?.average_ms_at_event_last_month
              ? this.convertMsToHours(
                this.response.average_ms_at_event_last_month
              )
              : 0,
            this.response?.average_ms_at_event_current_month
              ? this.convertMsToHours(
                this.response.average_ms_at_event_current_month
              )
              : 0,
          ],
        },
      ];
    },
  },

  watch: {
    period: {
      async handler() {
        this.$nextTick(() => {
          if (this.$refs.monthRef) {
            const element = this.$refs.monthRef.$el;
            const childCount = element.length;

            for (let i = 0; i < childCount; i++) {
              if (element[i].selected) {
                this.period.month = element[i].value;
              }
            }
          }
        });
        await this.getStatistics();
        this.getAverageMood();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.statsDatePicker .datepickComponent .vdpTable {
  display: none;
}
</style>
